import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { useEffect, useMemo } from 'react';

import { StandardMerkleTree } from '@openzeppelin/merkle-tree';
import config from '../constants/config';
import useSnackbarRpcError from './useSnackbarRpcError';
import useSnackbarRpcSuccess from './useSnackbarRpcSuccess';

export const getContractCallArgs = (
  walletAddress: string,
  walletsWhitelist: [string, string][]
) => {
  const tree = StandardMerkleTree.of(walletsWhitelist!, ['address', 'uint256']);

  const walletLeaf = walletsWhitelist!.find(
    ([address]) => address === walletAddress
  );

  if (walletLeaf) {
    const proof = tree.getProof(walletLeaf);
    const totalAmount = walletLeaf[1];

    return [proof, totalAmount];
  }
};

const useClaimHoneyRewardToWalletV2 = (
  walletAddress?: string,
  walletsWhitelist?: [string, string][],
  onSuccess?: () => void
): [
  claim: ReturnType<typeof useContractWrite>['write'],
  isLoading: boolean
] => {
  const args = useMemo(() => {
    if (walletAddress && walletsWhitelist?.length) {
      return getContractCallArgs(walletAddress, walletsWhitelist);
    }
  }, [walletAddress, walletsWhitelist]);

  const { config: writeConfig } = usePrepareContractWrite({
    addressOrName: config.contracts.fancyBearStakingRewardContractV2.address,
    contractInterface:
      config.contracts.fancyBearStakingRewardContractV2.interface,
    functionName: 'claimHoneyRewardToWallet',
    args,
    enabled: !!args,
  });

  const {
    write,
    data: writeData,
    error: writeError,
    isLoading,
  } = useContractWrite(writeConfig);

  const {
    data: waitData,
    error: waitError,
    isLoading: isWaitLoading,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 2,
  });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useEffect(() => {
    if (!isLoading && waitData && onSuccess) {
      onSuccess();
    }
  }, [isLoading, waitData, onSuccess]);

  return [write, isLoading || isWaitLoading];
};

export default useClaimHoneyRewardToWalletV2;
