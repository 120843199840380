import axios from 'axios';
import config from '../constants/config';

const WL_VERSION = '21.0';

export const fetchBearsWhitelist = async () => {
  const res = await axios.get<[number, string][]>(
    `/wls/fancyBears.wl.json?v=${WL_VERSION}`,
    {
      baseURL: config.urls.publicUrl,
    }
  );

  return res.data;
};

export const fetchWalletsWhitelist = async () => {
  const res = await axios.get<[string, string][]>(
    `/wls/wallets.wl.json?v=${WL_VERSION}`,
    {
      baseURL: config.urls.publicUrl,
    }
  );

  return res.data;
};

const whitelistsApi = {
  fetchBearsWhitelist,
  fetchWalletsWhitelist,
};

export default whitelistsApi;
